import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import "./index.css";

import iconRK from "../images/icon-rk.png";
import imageRobby from "../images/robby.jpg";
import imageSolarSystem from "../images/solar-system.jpg";

const device = {
  mobileS: `(max-width: 320px)`,
  mobileM: `(max-width: 414px)`,
  mobileL: `(max-width: 669px)`,
  tablet: `(max-width: 992px)`,
  laptop: `(max-width: 1250px)`,
  desktop: `(max-width: 1440px)`,
};

const IndexPage = () => {
  const [isActive, setIsActive] = useState();

  const handlePortfolioClick = (e) => {
    e.preventDefault();
    setIsActive((isActive) => !isActive);
  };

  const ContentDefault = (props) => (
    <Content {...props}>
      <FormatContent>
        <Icon>
          <img src={iconRK} width="100%" alt="RK" />
        </Icon>
        <h1>
          Robby Kanyur is a{" "}
          <a href="/" onClick={handlePortfolioClick}>
            digital product designer
          </a>{" "}
          based in Chicago, Illinois.
        </h1>
        <p>
          He currently works as a designer and researcher at{" "}
          <a href="https://facet.com" target="_blank" rel="noreferrer">
            Facet
          </a>
          .
        </p>
        <p className="overlap">
          Previous experience: tech startups, nonprofits, and one of the largest
          mortgage lenders in America.
        </p>
        <p>
          <a href="/Kanyur-Resume-2022-10.pdf" target="_blank" rel="noreferrer">
            &gt;&gt;&nbsp;Resume
          </a>
          <br />
          <a href="/" onClick={handlePortfolioClick}>
            &gt;&gt;&nbsp;Selected Work
          </a>
        </p>
      </FormatContent>
    </Content>
  );

  const ContentPortfolio = (props) => (
    <Content {...props}>
      <FormatContent>
        <h2 className="selected">Selected Work</h2>
        <PortfolioText>
          You can{" "}
          <a
            href="/Kanyur-Portfolio-2022-09.pdf"
            target="_blank"
            rel="noreferrer"
          >
            download my portfolio here
          </a>
          . If you need the password, please email{" "}
          <a href="mailto:robby@kanyur.com?Subject=Requesting%20password%20for%20portfolio">
            robby@kanyur.com
          </a>
          .
        </PortfolioText>
        <p>
          <a href="/" onClick={handlePortfolioClick}>
            Back to home
          </a>
        </p>
      </FormatContent>
    </Content>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>robby kanyur / digital product designer</title>
        <meta
          name="description"
          content="Robby Kanyur is a digital product designer based in Chicago, Illinois. He currently works as a designer and researcher at Fracture."
        />
        <meta property="og:image" content="/graph.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Background>
        <FullWrapper>
          <ContentWrapper>
            <ContentArea>
              <LoadAnimation
                as={motion.div}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.15,
                  easing: "easeInOut",
                  duration: 0.75,
                }}
              >
                <SliderAnimation as={AnimatePresence} initial={false}>
                  {!isActive && (
                    <ContentDefault
                      as={motion.div}
                      key="default"
                      initial={{ x: 1100, rotation: 0.16 }}
                      animate={{ x: 0, rotation: 0.16 }}
                      exit={{ x: 1100, rotation: 0.16 }}
                      transition={{ duration: 0.8, easing: "easeIn" }}
                    />
                  )}
                  {isActive && (
                    <ContentPortfolio
                      as={motion.div}
                      key="portfolio"
                      initial={{ x: -1100, rotation: 0.16 }}
                      animate={{ x: 0, rotation: 0.16 }}
                      exit={{ x: -1100, rotation: 0.16 }}
                      transition={{ duration: 0.8, easing: "easeIn" }}
                    />
                  )}
                </SliderAnimation>
              </LoadAnimation>
              <SolarSystem />
            </ContentArea>
            <ImageArea />
          </ContentWrapper>
        </FullWrapper>
      </Background>
    </React.Fragment>
  );
};

const Background = styled.div`
  background: #e3ded8;
  width: 100%;
  height: 100vh;
`;

const FullWrapper = styled.div`
  position: relative;
  width: 1440px;
  max-width: 100%;
  background: #ffffff;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  @media ${device.mobileM} {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const ContentArea = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  @media (max-height: 650px) and (min-width: 414px) {
    display: block;
  }
  @media ${device.mobileM} {
    display: block;
  }
`;

const LoadAnimation = styled.div`
  position: relative;
  height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  @supports (-webkit-touch-callout: none) {
    @media ${device.mobileM} {
      margin-top: -2rem;
    }
  }
`;

const SliderAnimation = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  padding: 0 5rem;
  z-index: 3000;
  min-width: 100%;
  @media (max-height: 650px) and (min-width: 414px) {
    max-height: 100vh;
    overflow-y: scroll;
    p.overlap {
      max-width: 70%;
    }
  }
  @media ${device.laptop} {
    padding: 0 5rem 0 3.5rem;
  }
  @media ${device.tablet} {
    width: 100%;
    min-width: 0;
    max-width: 70%;
  }
  @media ${device.mobileL} {
    max-width: 100%;
  }
  @media ${device.mobileM} {
    max-width: 100%;
    padding: 1.5rem 2rem;
    max-height: 100vh;
    overflow-y: scroll;
  }
  @media (max-width: 414px) and (min-height: 574px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const FormatContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media (max-height: 650px) and (min-width: 414px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @media ${device.laptop} {
    gap: 1.5rem;
  }
`;

const ImageArea = styled.div`
  position: relative;
  width: 600px;
  background: url(${imageRobby});
  background-size: cover;
  background-position: center;
  z-index: 5000;
  max-height: 100vh;
  @media ${device.laptop} {
    width: 500px;
  }
  @media ${device.mobileM} {
    display: none;
  }
`;

const Icon = styled.div`
  width: 75px;
  height: 75px;
  background: #0000f0;
  padding: 1rem;
`;

const SolarSystem = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 280px;
  height: 280px;
  background-image: url(${imageSolarSystem});
  background-size: cover;
  background-position: center;
  z-index: 2000;
  @media (max-height: 650px) and (min-width: 992px) {
    opacity: 0.1;
  }
  @media ${device.desktop} {
    width: 205px;
    height: 205px;
  }
  @media ${device.laptop} {
    width: 180px;
    height: 180px;
  }
  @media ${device.tablet} {
    width: 300px;
    height: 300px;
  }
  @media ${device.mobileL} {
    opacity: 0.1;
    position: fixed;
  }
`;

const PortfolioText = styled.p`
  max-width: 525px;
`;

export default IndexPage;
